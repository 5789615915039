<template>
  <StaticPage>
    <el-card class="form_wrapper">
      <h2>{{ $t('resetPassword.header') }}</h2>
      <div class="resetPassword_box" v-show="isAuthorized">
        <div class="from_box" v-show="formFlag">
          <el-form :model="ruleResetPasswordForm" :rules="rulesResetPassword" ref="ruleResetPasswordForm">
            <p :class="{ isError: isError }">
              {{ $t('common.formValidation.newPwFormat') }}
            </p>
            <InputTemplate
              v-model="ruleResetPasswordForm.pass"
              show-password
              name="pass"
              :label="$t('common.field.newPw')"
            >
            </InputTemplate>
            <InputTemplate
              v-model="ruleResetPasswordForm.checkPass"
              show-password
              name="checkPass"
              :label="$t('common.field.confirmPw')"
            >
            </InputTemplate>
            <el-button @click.native="submitForm('ruleResetPasswordForm')" data-testid="submit">
              {{ $t('common.button.submit') }}
            </el-button>
          </el-form>
        </div>
        <div class="result" v-show="successFlag">
          <i class="icon el-icon-circle-check text-green mb-3"></i>
          <p>{{ $t('resetPassword.succ2') }}</p>
          <router-link :to="{ name: 'login' }" class="el-button" data-testid="login">
            {{ $t('common.button.login') }}
          </router-link>
        </div>
      </div>
      <div class="resetPassword_box" v-show="isNotAuthroized">
        <p class="no_authroization">{{ $t('resetPassword.failed') }}</p>
      </div>
    </el-card>
    <PasswordRenewalReminderDialog :reminderDialogVisible.sync="reminderDialogVisible" @confirmSubmit="confirmSubmit" />
  </StaticPage>
</template>

<script>
import StaticPage from '@/components/template/staticPage/StaticPageDefault';
import { md5 } from '@/util/encrypt';
import { encrypt } from '@/util/rsa/JSEncrypt.js';
import {
  apiCheckResetPasswordAuthorization,
  apiSubmitResetProfilePassword,
  apiEncryptPublicKey
} from '@/resource';
import { validatePass } from '@/util/validation';
import PasswordRenewalReminderDialog from '@/components/auth/PasswordRenewalReminderDialog.vue';

export default {
  name: 'ResetProfilePassword',
  components: { StaticPage, PasswordRenewalReminderDialog },
  data() {
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('common.formValidation.newPwReq')));
      } else if (value !== this.ruleResetPasswordForm.pass) {
        this.isError = true;
        callback(new Error(this.$t('common.formValidation.confirmNotMatch')));
      } else {
        this.isError = false;
        callback();
      }
    };
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      rulesResetPassword: {
        pass: [{ validator: validatePass, trigger: 'blur' }],
        checkPass: [{ validator: validatePass2, trigger: 'blur' }]
      },
      reminderDialogVisible: false
    };
  },
  mounted() {
    const token = this.$route.query.token;
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: this.$route.query.token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    async confirmSubmit() {
      let result = await apiEncryptPublicKey(this.ruleResetPasswordForm.token);
      let publicKey = result.data.data;
      apiSubmitResetProfilePassword(
        {
          token: this.ruleResetPasswordForm.token,
          pass: encrypt(this.ruleResetPasswordForm.pass, publicKey),
          checkPass: encrypt(this.ruleResetPasswordForm.checkPass, publicKey)
        },
        publicKey
      ).then(resp => {
        if (resp.data.code == 0 && resp.data.data.result == true) {
          this.formFlag = false;
          this.successFlag = true;
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.reminderDialogVisible = true;
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/loginClient/login.scss';
@import '@/assets/css/pages/resetPassword.scss';
</style>
